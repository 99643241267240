/* line 2, ../sass/components/neat/grid/_grid.scss */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 11, ../sass/components/neat/grid/_visual-grid.scss */
body:before {
  content: '';
  display: inline-block;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 68em;
  opacity: 0.4;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: -1;
}
@media screen and (max-width: 480px) {
}
@media screen and (max-width: 768px) {
}

/* Layout
 * ------
 * Using a negative margin technique, adapted from ZEN. The page is loaded by this order:
 *
 * 1. Header
 * 2. Content
 * 3. Navigation menus
 * 4. Sidebar Left
 * 5. Sideabr Right */
/* Layout rules. (Disclaimer: do not change if you're not sure you know what you're doing.) */
/* line 14, ../sass/layout.sass */
#page {
  *zoom: 1;
  margin:0 auto;
  width: 100%;
}
/* line 18, ../sass/components/bourbon/addons/_clearfix.scss */
#page:before, #page:after {
  content: " ";
  display: table;
}
/* line 23, ../sass/components/bourbon/addons/_clearfix.scss */
#page:after {
  clear: both;
}
@media screen and (max-width: 68em) {
  /* line 14, ../sass/layout.sass */
  #page {
    width: auto;
  }
}

/* line 24, ../sass/layout.sass */
.no-sidebars #content {
  display: block;
  float: left;
  margin-right: 2.12766%;
  width: 100%;
}
/* line 54, ../sass/components/neat/grid/_span-columns.scss */
.no-sidebars #content:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  /* line 24, ../sass/layout.sass */
  .no-sidebars #content {
    display: block;
    float: left;
    margin-right: 3.22581%;
    width: 100%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  .no-sidebars #content:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 480px) {
  /* line 24, ../sass/layout.sass */
  .no-sidebars #content {
    display: block;
    float: left;
    margin-right: 6.66667%;
    width: 100%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  .no-sidebars #content:last-child {
    margin-right: 0;
  }
}
/* line 33, ../sass/layout.sass */
.one-sidebar.sidebar-second #content {
  display: block;
  float: left;
  margin-right: 2.12766%;
  width: 74.46809%;
}
/* line 54, ../sass/components/neat/grid/_span-columns.scss */
.one-sidebar.sidebar-second #content:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  /* line 33, ../sass/layout.sass */
  .one-sidebar.sidebar-second #content {
    display: block;
    float: left;
    margin-right: 3.22581%;
    width: 74.19355%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  .one-sidebar.sidebar-second #content:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 480px) {
  /* line 33, ../sass/layout.sass */
  .one-sidebar.sidebar-second #content {
    display: block;
    float: left;
    margin-right: 6.66667%;
    width: 100%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  .one-sidebar.sidebar-second #content:last-child {
    margin-right: 0;
  }
}
/* line 42, ../sass/layout.sass */
.one-sidebar.sidebar-first #content {
  display: block;
  float: left;
  margin-right: 2.12766%;
  width: 82.97872%;
  margin-left: 17.02128%;
}
/* line 54, ../sass/components/neat/grid/_span-columns.scss */
.one-sidebar.sidebar-first #content:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  /* line 42, ../sass/layout.sass */
  .one-sidebar.sidebar-first #content {
    display: block;
    float: left;
    margin-right: 3.22581%;
    width: 61.29032%;
    margin-left: 25.80645%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  .one-sidebar.sidebar-first #content:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 480px) {
  /* line 42, ../sass/layout.sass */
  .one-sidebar.sidebar-first #content {
    display: block;
    float: left;
    margin-right: 6.66667%;
    width: 100%;
    margin-left: 0%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  .one-sidebar.sidebar-first #content:last-child {
    margin-right: 0;
  }
}
/* line 54, ../sass/layout.sass */
.two-sidebars #content {
  display: block;
  float: left;
  margin-right: 2.12766%;
  width: 57.44681%;
  margin-left: 17.02128%;
}
/* line 54, ../sass/components/neat/grid/_span-columns.scss */
.two-sidebars #content:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  /* line 54, ../sass/layout.sass */
  .two-sidebars #content {
    display: block;
    float: left;
    margin-right: 3.22581%;
    width: 48.3871%;
    margin-left: 25.80645%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  .two-sidebars #content:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 480px) {
  /* line 54, ../sass/layout.sass */
  .two-sidebars #content {
    display: block;
    float: left;
    margin-right: 6.66667%;
    width: 100%;
    margin-left: 0%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  .two-sidebars #content:last-child {
    margin-right: 0;
  }
}

/* line 68, ../sass/layout.sass */
#sidebar-first {
  display: block;
  float: left;
  margin-right: 2.12766%;
  width: 14.89362%;
  margin-left: -76.59574%;
}
/* line 54, ../sass/components/neat/grid/_span-columns.scss */
#sidebar-first:last-child {
  margin-right: 0;
}
/* line 73, ../sass/layout.sass */
.sidebar-first #sidebar-first {
  margin-left: -102.12766%;
}
@media screen and (max-width: 768px) {
  /* line 68, ../sass/layout.sass */
  #sidebar-first {
    display: block;
    float: left;
    margin-right: 3.22581%;
    width: 22.58065%;
    margin-left: -77.41935%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  #sidebar-first:last-child {
    margin-right: 0;
  }
  /* line 79, ../sass/layout.sass */
  .sidebar-first #sidebar-first {
    margin-left: -90.32258%;
  }
}
@media screen and (max-width: 480px) {
  /* line 68, ../sass/layout.sass */
  #sidebar-first {
    display: block;
    float: left;
    margin-right: 6.66667%;
    width: 100%;
    margin-left: 0%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  #sidebar-first:last-child {
    margin-right: 0;
  }
  /* line 85, ../sass/layout.sass */
  .sidebar-first #sidebar-first {
    margin-left: 0%;
  }
}

/* line 88, ../sass/layout.sass */
#sidebar-second {
  display: block;
  float: left;
  margin-right: 2.12766%;
  width: 23.40426%;
  margin-left: 0%;
}
/* line 54, ../sass/components/neat/grid/_span-columns.scss */
#sidebar-second:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  /* line 88, ../sass/layout.sass */
  #sidebar-second {
    display: block;
    float: left;
    margin-right: 3.22581%;
    width: 22.58065%;
    margin-left: 0%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  #sidebar-second:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 480px) {
  /* line 88, ../sass/layout.sass */
  #sidebar-second {
    display: block;
    float: left;
    margin-right: 6.66667%;
    width: 100%;
    margin-left: 0%;
  }
  /* line 54, ../sass/components/neat/grid/_span-columns.scss */
  #sidebar-second:last-child {
    margin-right: 0;
  }
}

/* line 101, ../sass/layout.sass */
#footer {
  float: none;
  clear: both;
}

/* Layout Helpers */
/* line 106, ../sass/layout.sass */
#header,
#footer,
.mission,
.breadcrumb,
.node {
  clear: both;
}

/* Columns Inner
 * -------------
 * You can change the padding inside the columns without changing the
 * width of them by just usinbg the INNER div of each column */
/* line 117, ../sass/layout.sass */
.inner {
  padding: 0;
}

/* Navigation styles */
/* line 122, ../sass/layout.sass */
#navigation li {
  list-style-type: none;
  display: inline-block;
}
